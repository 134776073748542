<template>
    <div>
        <DxValidationGroup ref="formValidation">
            <span class="font-12">Mã số lệnh</span>
            <DxTextBox
                class="mb-3"
                v-model="model.MaLenh"
                validationMessageMode="always"
                mode="text"
                :read-only="true"
                styling-mode="underlined"
            ></DxTextBox>
            <!-- mask="0000/00.00000" -->
            <span class="font-12">Chi tiết sự cố (*)</span>
            <DxTextBox
                class="mb-4"
                v-model="model.LyDo"
                validationMessageMode="always"
                mode="text"
                styling-mode="underlined"
                :showClearButton='true'
            >
                <DxValidator>
                    <DxRequiredRule message="Chi tiết sự cố không được bỏ trống!" />
                    <DxStringLengthRule
                        :max="128"
                        message="Chi tiết sự cố không được vượt quá 128 ký tự!"
                    />
                </DxValidator>
            </DxTextBox>
            <div class="row mb-2 align-end">
                <div class="font-bold pr-2">Hình ảnh sự cố</div>
                <div class="font-12">{{ ArrPreviewImg.length }}/6</div>
            </div>
            <div class="row">
                <ListAnhChupVue
                    v-model:ArrPreviewImg="ArrPreviewImg"
                    v-model:ArrImgPush="model.DanhSachHinhAnh"
                    :SoLuongAnh="6"
                    width="40px"
                    height="40px"
                    HinhDang="circle"
                />

                <!-- <div v-for="(item, index) in data.DanhSachHinhAnh" :key="index">
                    <div
                        class="
                            anh-dai-dien-style
                            row
                            justify-center
                            align-center
                            ml-2
                        "
                        @click="getAnh"
                    >
                        <img
                            :src="item"
                            alt=""
                            width="100%"
                            height="auto"
                            v-if="item"
                        />
                        <i
                            v-else
                            class="mdi mdi-camera-plus-outline"
                            style="opacity: 0.5"
                        ></i>
                    </div>
                </div>
                <div
                    class="
                        anh-dai-dien-style
                        row
                        justify-center
                        align-center
                        ml-2
                    "
                    @click="getAnh"
                >
                    <i
                        class="mdi mdi-camera-plus-outline"
                        style="opacity: 0.5"
                    ></i>
                </div> -->
            </div>
        </DxValidationGroup>
    </div>
</template>
<script>
import { DxTextBox, DxValidationGroup, DxValidator } from "devextreme-vue";
import { DxRequiredRule, DxStringLengthRule } from "devextreme-vue/data-grid";
import ListAnhChupVue from "../../Kiem-Soat-Hang-Hoa/components/ListAnhChup.vue";
export default {
    components: {
        DxValidationGroup,
        DxTextBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        ListAnhChupVue,
    },
    props: {
        lenhDangThucHien: {
            type: Object,
            default: () => {},
        },
        statePopup: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            url: "",
            model: {
                MaLenh: "",
                LyDo: "",
                DanhSachHinhAnh: [],
            },
            data: {
                DanhSachHinhAnh: [],
            },
            ArrPreviewImg: [],
        };
    },
    watch: {
        statePopup: {
            handler: function () {
                if (this.statePopup) {
                    this.ArrPreviewImg = [];
                    this.model.LyDo = "Hỏng xe";
                    this.model.DanhSachHinhAnh = [];
                    if (this.lenhDangThucHien) {
                        this.model.MaLenh = this.lenhDangThucHien.maLenh || null;
                        this.ArrPreviewImg = this.lenhDangThucHien.danhSachHinhAnhSuCo
                            ? this.lenhDangThucHien.danhSachHinhAnhSuCo
                            : [];
                    }
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {},
    mounted() {},
};
</script>
<style scoped></style>
<style lang="scss" scoped>
.anh-dai-dien-style {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid $border;
    overflow: hidden;
}
</style>
