<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div
                            class="shrink"
                            :style="!DaDangKySuDungLenh ? 'opacity: 0' : ''"
                        >
                            <DxButton
                                type="default"
                                icon="mdi mdi-close"
                                class="ml-1"
                                @click="
                                    $router.push({
                                        path: '/DanhSachLenh',
                                    })
                                "
                                :disabled="!DaDangKySuDungLenh"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">LỆNH VẬN CHUYỂN</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            /></div
                    ></slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false">
            <div
                v-if="!DaDangKySuDungLenh"
                class="row pa-2 pt-3 justify-center align-center text-xs-center font-16 font-medium"
                style="height: calc(100vh - 48px - 36px); position: relative"
            >
                Bạn chưa đăng ký sử dụng dịch vụ Lệnh điện tử. Vui lòng liên hệ Công ty
                của bạn để được cập nhật!
            </div>
            <div v-else-if="!LoadXongDuLieu"></div>
            <div
                class="row pa-2 pt-3"
                style="height: calc(100vh - 48px - 36px); position: relative"
                v-else-if="ChiTietLenhDangThucHien.maLenh && LoadXongDuLieu"
            >
                <div
                    class="trang-thai"
                    :style="`color: ${model.MaMau}; border-color: ${model.MaMau}`"
                    v-if="model.TrangThai"
                >
                    {{ model.TrangThai }}
                </div>
                <div class="xs12">
                    <div
                        class="row font-16 font-medium text-xs-center justify-center text-link"
                        @click="dialogBanTheHienLenh = true"
                    >
                        {{ MaChuyenDi }}
                    </div>

                    <div class="row justify-center align-center qrcodes">
                        <img
                            v-if="
                                ChiTietLenhDangThucHien && ChiTietLenhDangThucHien.qrCode
                            "
                            :src="`https://image-charts.com/chart?cht=qr&chs=244x244&choe=UTF-8&chld=L&chl=${ChiTietLenhDangThucHien.qrCode}`"
                            style="
                                width: 224px !important;
                                height: 224px !important;
                                max-width: unset;
                            "
                            alt=""
                        />
                    </div>

                    <div class="row font-medium font-16 justify-center">
                        {{ model.BienSo }}
                    </div>

                    <div class="row justify-center font-italic mb-2">
                        {{ $Helper.formatDateTime(model.GioXuatBenKeHoach) }}
                    </div>

                    <div :class="row">
                        <div :class="textLeft">Mã tuyến</div>
                        <div :class="textRight">
                            {{ model.MaTuyen }}
                        </div>
                    </div>
                    <div :class="row">
                        <div :class="textLeft">Tên bến đi</div>
                        <div :class="textRight">
                            {{ model.TenBenDi }}
                        </div>
                    </div>
                    <div :class="row">
                        <div :class="textLeft">Tên bến đến</div>
                        <div :class="textRight">
                            {{ model.TenBenDen }}
                        </div>
                    </div>
                    <div :class="row">
                        <div :class="textLeft">Số khách đã mua vé</div>
                        <div :class="textRight" class="color-success">
                            {{ model.SoKhachDaMuaVe || 0 }}
                        </div>
                    </div>
                    <div :class="row">
                        <div :class="textLeft">Số khách trên xe</div>
                        <div :class="textRight" class="color-primary">
                            {{ model.SoKhachDangTrenXe || 0 }}
                        </div>
                    </div>
                </div>

                <!-- <div
                    v-if="chuyenDiGanDay"
                    class="fab-float-button row justify-center align-center"
                >
                    <DxButton
                        text="Đến bến"
                        type="danger"
                        styling-mode="text"
                        icon="mdi mdi-bus-stop"
                        
                    />
                </div> -->
            </div>

            <div
                class="row pa-2 pt-3 justify-center align-center text-xs-center font-16 font-medium"
                style="height: calc(100vh - 48px - 36px); position: relative"
                v-else
            >
                Không tìm thấy thông tin chuyến đi!
            </div>

            <DxSpeedDialAction
                :visible="
                    hiddenFloatBtn &&
                    ChiTietLenhDangThucHien.maLenh &&
                    (ChiTietLenhDangThucHien.idTrangThai ==
                        $t('TrangThaiLenh.BenDiDaKy') ||
                        ChiTietLenhDangThucHien.idTrangThai ==
                            $t('TrangThaiLenh.ChoXacNhanTraKhach'))
                "
                :index="1"
                icon="mdi mdi-bus-stop"
                label="Xác nhận đến bến"
                @click="
                    ParamThongBao = {
                        state: 'Warning',
                        title: 'Xác nhận kết thúc chuyến đi',
                        message: 'Bạn có chắc chắn muốn kết thúc chuyến đi này?',
                    };
                    dialogKetThucChuyenDi = true;
                "
            />
            <DxSpeedDialAction
                :visible="hiddenFloatBtn && ChiTietLenhDangThucHien.maLenh"
                :index="2"
                icon="mdi mdi-stop-circle"
                label="Xác nhận xe gặp sự cố"
                @click="dialogDungHanhTrinh = true"
            />

            <DxSpeedDialAction
                :visible="hiddenFloatBtn && ChiTietLenhDangThucHien.maLenh"
                :index="4"
                icon="mdi mdi-ticket-confirmation hiddenBtn"
                :onContentReady="hiddenFloatButton"
                label="Bán vé cho ghế phụ"
            />
        </ion-content>

        <PopupVue
            height="auto"
            v-model:dialog="dialogKetThucChuyenDi"
            title=""
            buttonTextLeft="Hủy"
            class="popup-padding-8"
            @close="xacNhanKetThucChuyenDi"
            heightScrollView="auto"
        >
            <template #content>
                <ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>

        <!-- ====================================Popup Dừng hành trình ========================================= -->
        <PopupVue
            height="350px"
            v-model:dialog="dialogDungHanhTrinh"
            title="Xác nhận xe gặp sự cố"
            buttonTextRight="Xác nhận"
            buttonTextLeft="Hủy"
            classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16 popup-padding-top-16"
            @close="closeDungHanhTrinh"
        >
            <template #content>
                <PopupDungHanhTrinhVue
                    ref="DungHanhTrinh"
                    :statePopup="dialogDungHanhTrinh"
                    :lenhDangThucHien="ChiTietLenhDangThucHien"
                />
            </template>
        </PopupVue>

        <!-- Thông báo lỗi -->
        <PopupVue
            height="auto"
            v-model:dialog="dialogThongBao"
            title=""
            :useButtonLeft="false"
            @close="
                () => {
                    dialogThongBao = false;
                }
            "
            heightScrollView="auto"
        >
            <template #content>
                <ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>

        <PopupBanTheHienLenhVue
            :dialog="dialogBanTheHienLenh"
            :guidLenh="ChiTietLenhDangThucHien.guidLenh"
            @closes="
                () => {
                    dialogBanTheHienLenh = false;
                }
            "
        />
    </ion-page>
</template>

<script>
import config from "devextreme/core/config";
import repaintFloatingActionButton from "devextreme/ui/speed_dial_action/repaint_floating_action_button";
import {
    IonContent,
    IonPage,
    IonToolbar,
    IonHeader,
    onIonViewWillEnter,
    onIonViewWillLeave,
} from "@ionic/vue";
import { DxButton, DxSpeedDialAction } from "devextreme-vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import ChuyenDi from "../../class/ChuyenDi";
import ThongBaoVue from "../../src/components/_Common/ThongBao";
import PopupVue from "../components/_Common/Popup.vue";
import PopupBanTheHienLenhVue from "./Quan-Ly-Lenh/components/PopupBanTheHienLenh";
import PopupDungHanhTrinhVue from "./Quan-Ly-Lenh/components/PopupDungHanhTrinh";
import LaiXe from "../../class/LaiXe";
var QRCode = require("qrcode");
export default {
    components: {
        IonContent,
        IonPage,
        IonToolbar,
        IonHeader,
        DxButton,
        ThongBaoVue,
        PopupVue,
        DxSpeedDialAction,
        PopupDungHanhTrinhVue,
        PopupBanTheHienLenhVue,
    },
    data() {
        return {
            dialogBanTheHienLenh: false,
            guidChuyenDi: null,
            MaChuyenDi: "",
            qrcode: null,
            textLeft: "xs5 font-14 font-regular",
            textRight: "xs7 font-14 pl-3 font-medium text-xs-right",
            row: "row mb-1 px-2 align-center",
            model: {
                MaTuyen: "",
                TenBenDi: "",
                TenBenDen: "",
                SoKhachDaMuaVe: 0,
                SoKhachDangTrenXe: 0,
                TrangThai: "",
                BienSo: null,
                GioXuatBenKeHoach: new Date(),
                MaMau: "#000",
            },
            dialogThongBao: false,
            dialogKetThucChuyenDi: false,
            dialogTiepNhanLenh: false,
            dialogDungHanhTrinh: false,
            hiddenFloatBtn: true,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            ChiTietLenhDangThucHien: {},
            DaDangKySuDungLenh: false,
            LoadXongDuLieu: false,
        };
    },
    computed: {
        ...mapGetters("Ve", ["chuyenDiGanDay"]),
    },
    methods: {
        ...mapActions("Ve", ["layChuyenDiGanDay", "LayThongTinThemCuaChuyenDi"]),
        ...mapActions("Lenh", ["layChiTietLenhDangThucHien"]),
        toastThongBao(type, message) {
            let toast = {
                message: message,
                type: type,
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
        thongBaoLoi(message, log) {
            this.dialogKetThucChuyenDi = false;
            this.thongBao("Error", message || "Đã xảy ra lỗi. Vui lòng thử lại");
        },
        async xacNhanKetThucChuyenDi(param) {
            if (!param) {
                this.dialogKetThucChuyenDi = false;
                return;
            }
            let toast = {
                type: "error",
                message: "",
                visible: true,
            };
            try {
                let dataPost = {
                    idLenh: this.ChiTietLenhDangThucHien.guidLenh,
                };
                let rs = await this.$store.dispatch("Lenh/KetThucChuyenDi", dataPost);
                if (rs.Data.status == true) {
                    this.dialogKetThucChuyenDi = false;
                    this.toastThongBao("success", "Kết thúc chuyến đi thành công!");
                    this.$router.push({
                        path: "/DanhSachLenh",
                    });
                } else {
                    toast.message = rs.Data.message;
                    this.emitter.emit("onToast", toast);
                }
            } catch (error) {
                console.log("🚀 = error", error);
                toast.message = "Lỗi hệ thống!";
                this.emitter.emit("onToast", toast);
            }
        },
        getQrCode() {
            QRCode.toCanvas(
                document.getElementById("qrcode"),
                this.ChiTietLenhDangThucHien.qrCode,
                { errorCorrectionLevel: "L", margin: 2 },
                function (error) {
                    if (error) console.error(error);
                    //success
                },
            );
        },
        getData() {
            this.layChiTietLenh();
        },
        async layChiTietLenh() {
            this.resetData();
            let toast = {
                message: "Không tìm thấy chuyến đi!",
                type: "error",
                visible: true,
            };
            try {
                let rs = await this.layChiTietLenhDangThucHien();
                this.LoadXongDuLieu = true;
                if (!rs) {
                    this.toastThongBao("error", "Đã xảy ra lỗi. Vui lòng thử lại!");
                    this.emitter.emit("onToast", toast);
                    return;
                }
                if (!rs.Data.status) {
                    // this.toastThongBao(
                    //     "error",
                    //     "Lấy chi tiết lệnh thất bại! " + rs.Data.message
                    // );
                    this.$router.push({
                        path: "/DanhSachLenh",
                    });
                    return;
                }
                let chiTietLenh = rs.Data.data;
                this.ChiTietLenhDangThucHien = chiTietLenh || {};
                this.guidLenh = chiTietLenh.guidLenh || null;
                this.MaChuyenDi = chiTietLenh.maLenh || null;
                this.model.TenBenDi = chiTietLenh.benDi || null;
                this.model.TenBenDen = chiTietLenh.benDen || null;
                this.model.MaTuyen = chiTietLenh.maTuyen || null;
                this.model.TrangThai = chiTietLenh.trangThai || null;
                this.model.MaMau = chiTietLenh.maMauTrangThai.trim() || "#000";
                this.model.BienSo = chiTietLenh.bienKiemSoat || null;
                this.model.GioXuatBenKeHoach = chiTietLenh.gioXuatBen;
                this.LayThongTinChuyenDiTheoLenh(chiTietLenh.guidLenh);
                // this.getQrCode();
            } catch (error) {
                console.error(error);
            }
        },
        resetData() {
            this.ChiTietLenhDangThucHien = {};
            this.guidLenh = null;
            this.MaChuyenDi = null;
            this.model.TenBenDi = null;
            this.model.TenBenDen = null;
            this.model.MaTuyen = null;
            this.model.TrangThai = null;
            this.model.MaMau = null;
            this.model.BienSo = null;
            this.model.GioXuatBenKeHoach = null;
        },
        async LayThongTinChuyenDiTheoLenh(idLenhDienTu) {
            try {
                let rs = await this.$store.dispatch(
                    "ThongTin/LayThongTinChuyenDiTheoLenh",
                    idLenhDienTu,
                );
                if (rs.Data.status === true) {
                    let ThongTinChuyenDi = rs.Data.data;
                    this.model.SoKhachDaMuaVe = ThongTinChuyenDi.soKhachMuaVe || 0;
                    this.model.SoKhachDangTrenXe = ThongTinChuyenDi.soKhachTrenXe || 0;
                }
            } catch (error) {
                console.error(error);
            }
        },
        async capNhatTrang() {
            this.getData();
        },
        hiddenFloatButton() {
            let elm = document.querySelectorAll(".hiddenBtn");
            if (elm.length > 0) {
                setTimeout(() => {
                    elm.forEach((e) => {
                        e.parentElement.parentElement.style.display = "none";
                    });
                }, 10);
            }
        },
        async closeDungHanhTrinh(param) {
            if (!param || !this.$refs.DungHanhTrinh) {
                this.dialogDungHanhTrinh = false;
                return;
            }
            try {
                let valid = this.$refs.DungHanhTrinh.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return;
                }
                let laiXe = new LaiXe();
                laiXe.maLenh = this.$refs.DungHanhTrinh.model.Malenh;
                laiXe.lyDo = this.$refs.DungHanhTrinh.model.LyDo;
                laiXe.danhSachHinhAnh = this.$refs.DungHanhTrinh.model.DanhSachHinhAnh;
                // Thực hiện dừng hành trình
                let rs = await laiXe.dungHanhTrinh(this.ChiTietLenhDangThucHien.guidLenh);
                this.dialogDungHanhTrinh = false;
                if (!rs) {
                    throw new Error();
                }
                if (!rs.Data.status) {
                    return this.toastThongBao(
                        "error",
                        "Xác nhận xe gặp sự cố thất bại! " + rs.Data.message,
                    );
                }
                this.$router.push({
                    path: "/thanh-cong",
                    query: {
                        title: "Đã xác nhận xe gặp sự cố",
                        message: `Thông báo sẽ được gửi đến đơn vị ${this.ChiTietLenhDangThucHien.tenDoanhNghiep}!`,
                        guidLenh: this.ChiTietLenhDangThucHien.guidLenh,
                    },
                });
            } catch (error) {
                console.log(
                    "🚀 ~ file: index.vue ~ line 284 ~ closeDungHanhTrinh ~ error",
                    error,
                );
                this.thongBaoLoi(error);
            }
        },
    },
    created() {
        onIonViewWillEnter(async () => {
            this.DaDangKySuDungLenh = false;
            this.LoadXongDuLieu = false;
            this.hiddenFloatBtn = true;
            localStorage.removeItem("ChoDaChon");
            let SuDungDichVu = this.$Core.AuthApi.TokenParsed.SuDungDichVu || null;
            if (SuDungDichVu) {
                let arrDichVu = SuDungDichVu.split(";");
                arrDichVu.forEach(async (e) => {
                    if (e == this.$t("StringCheck.SuDungLenh")) {
                        this.DaDangKySuDungLenh = true;
                        this.getData();
                    }
                });
            } else {
                this.getData();
            }
        });
    },
    mounted() {
        config({
            floatingActionButtonConfig: {
                icon: "mdi mdi-menu",
                position: {
                    at: "right bottom",
                    my: "right bottom",
                    offset: "-16 -44",
                },
                shading: true,
            },
        });
        repaintFloatingActionButton();

        onIonViewWillLeave(() => {
            this.hiddenFloatBtn = false;
            // this.closeAllDialog();
        });
    },
};
</script>

<style scoped>
>>> .custom-toolbar .toolbar-container {
    height: 48px;
}
.qrcodes {
    position: relative;
    /* max-width: 180px;
    max-height: 180px; */
    height: 188px;
    width: 100%;
    overflow: hidden;
}

.trang-thai {
    position: absolute;
    top: 44px;
    right: -60px;
    transform: rotate(45deg);
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 4px 0;
    width: 224px;
    text-align: center;
    z-index: 1;
    opacity: 0.8;
}
>>> .fab-float-button .dx-button {
    border-radius: 50px;
}
</style>

<style lang="scss" scoped>
.fab-float-button {
    box-shadow: $box_shadow;
    border-radius: 50px;
    width: max-content;
    // height: 32px;
    position: fixed;
    right: 16px;
    bottom: 8px;
    z-index: 10;
    background-color: white;
}
.fab-float-button:active {
    opacity: 0.7;
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .fab-float-button {
        bottom: 48px;
    }
}
</style>
