<template>
    <DxPopup
        :visible="dialog"
        :close-on-outside-click="false"
        :show-close-button="false"
        :show-title="false"
        width="100vw"
        height="100%"
        :shading="true"
        position="bottom"
        :wrapperAttr="{
            class: 'clear-padding',
        }"
        :animation="{
            show: {
                type: 'slide',
                duration: 400,
                from: {
                    position: {
                        my: 'top',
                        at: 'bottom',
                        of: 'window',
                    },
                },
            },
            hide: {
                type: 'slide',
                duration: 400,
                to: {
                    position: {
                        my: 'top',
                        at: 'bottom',
                        of: 'window',
                    },
                },
            },
        }"
    >
        <DxButton
            type="normal"
            styling-mode="text"
            icon="mdi mdi-close"
            @click="$emit('closes', false)"
            class="button-style white--text"
            style="position: fixed; top: 10px; left: 8px; z-index: 1"
        />
        <div
            class="full-height row align-center"
            style="width: 100vw; background-color: black"
        >
            <!-- <embed :src="link" alt="" width="100%" height="auto" /> -->
            <!-- <canvas id="canvas"></canvas> -->
            <div class="white--bg py-2" style="width: 100vw; height: calc(100vh - 96px)">
                <div class="full-height row align-center">
                    <div
                        class="btn-prev"
                        v-if="
                            listGallery.length > 1 &&
                            selectedItemBanTheHienLenh?.index != 0
                        "
                    >
                        <DxButton
                            type="norrmal"
                            styling-mode="text"
                            icon="mdi mdi-chevron-left"
                            @click="prevItem()"
                        />
                    </div>
                    <div
                        class="btn-next"
                        v-if="
                            listGallery.length > 1 &&
                            selectedItemBanTheHienLenh?.index != listGallery.length - 1
                        "
                    >
                        <DxButton
                            type="norrmal"
                            styling-mode="text"
                            icon="mdi mdi-chevron-right"
                            @click="nextItem()"
                        />
                    </div>
                    <canvas
                        v-if="isShow"
                        v-show="selectedItemBanTheHienLenh?.type == 'pdf'"
                        id="the-canvas"
                        style="
                            direction: ltr;
                            width: 100vw !important;
                            height: auto !important;
                            overflow: auto;
                        "
                    ></canvas>
                    <img
                        v-if="isShow"
                        v-show="selectedItemBanTheHienLenh?.type != 'pdf'"
                        :src="selectedItemBanTheHienLenh.src"
                        id="img-view"
                        style="width: 100%; height: auto"
                    />
                </div>
            </div>
        </div>
    </DxPopup>
</template>
<script>
import { DxPopup, DxButton } from "devextreme-vue";
import LenhDienTu from "../../../../class/LenhDienTu";
import Panzoom from "@panzoom/panzoom";

export default {
    layout: "application",
    components: { DxPopup, DxButton },
    props: {
        dialog: {
            type: Boolean,
            default: false,
        },
        guidLenh: {
            type: String,
            default: "",
        },
        laLenhGiay: {
            type: Boolean,
            default: false,
        },
        danhSachLenhGiay: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            link: "",
            isShow: false,
            panzoom: null,
            countClick: 0,
            timeOutClick: null,
            isZoomedIn: false,
            selectedItemBanTheHienLenh: null,
            listGallery: [],
        };
    },
    methods: {
        async getData() {
            this.listGallery = [];
            if (this.laLenhGiay) {
                this.LayBanTheHienLenhGiay();
            } else {
                this.LayBanTheHienLenhDienTu();
            }
            setTimeout(() => {
                this.XuLyZoom();
            }, 900);
        },
        XuLyZoom() {
            // arrCanvas.addEventListener("panzoompan", function (e) {
            //     console.log("panzoompan", e.detail, arrCanvas.getClientRects()[0], arrCanvas.parentElement.getClientRects()[0]);
            //     e.preventDefault();
            // });

            // var canvasElm = document.getElementById("the-canvas");
            // // Bắt sự kiện kích đúp chuột để phóng to và thu nhỏ
            let self = this;
            let arrCanvas = document.querySelectorAll("#the-canvas");
            arrCanvas.forEach((canvas) => {
                canvas.addEventListener("click", function (event) {
                    self.countClick++;
                    clearTimeout(self.timeOutClick);
                    if (self.countClick == 2) {
                        var rect = canvas.getBoundingClientRect();
                        var clientX = event.clientX - rect.left;
                        var clientY = event.clientY - rect.top;

                        // Thu phóng 2 lần khi kích đúp chuột
                        var scale = self.panzoom.getScale() * 2;

                        // Tự động điều chỉnh tâm của phóng ảnh
                        if (self.isZoomedIn) {
                            self.panzoom.pan(0, 0);
                            self.panzoom.zoom(1);
                            self.isZoomedIn = false;
                        } else {
                            // Zoom in khi kích đúp chuột trong trạng thái thu nhỏ hoặc bình thường
                            var scale = self.panzoom.getScale() * 2.5;
                            self.panzoom.zoomToPoint(scale, {
                                clientX: clientX,
                                clientY: clientY,
                            });
                            self.isZoomedIn = true;
                        }
                        self.countClick = 0;
                    } else {
                        self.timeOutClick = setTimeout(() => {
                            self.countClick = 0;
                        }, 600);
                    }
                });
            });
            let elmImg = document.getElementById("img-view");
            elmImg.addEventListener("click", function (event) {
                self.countClick++;
                clearTimeout(self.timeOutClick);
                if (self.countClick == 2) {
                    var rect = elmImg.getBoundingClientRect();
                    var clientX = event.clientX - rect.left;
                    var clientY = event.clientY - rect.top;

                    // Thu phóng 2 lần khi kích đúp chuột
                    var scale = self.panzoom.getScale() * 2;

                    // Tự động điều chỉnh tâm của phóng ảnh
                    if (self.isZoomedIn) {
                        self.panzoom.pan(0, 0);
                        self.panzoom.zoom(1);
                        self.isZoomedIn = false;
                    } else {
                        // Zoom in khi kích đúp chuột trong trạng thái thu nhỏ hoặc bình thường
                        var scale = self.panzoom.getScale() * 2.5;
                        self.panzoom.zoomToPoint(scale, {
                            clientX: clientX,
                            clientY: clientY,
                        });
                        self.isZoomedIn = true;
                    }
                    self.countClick = 0;
                } else {
                    self.timeOutClick = setTimeout(() => {
                        self.countClick = 0;
                    }, 600);
                }
            });
        },
        async LayBanTheHienLenhGiay() {
            this.isShow = false;
            for (let index = 0; index < this.danhSachLenhGiay.length; index++) {
                try {
                    let rs = await this.$Core.Api.LenhMobile(this.danhSachLenhGiay[index])
                        .Config((c) => {
                            c.responseType = "blob";
                            c.validateStatus = () => true;
                        })
                        .Get();

                    let src = URL.createObjectURL(rs.Data);
                    let checkType = rs.Data.type.indexOf("pdf");
                    this.listGallery.push({
                        index: index,
                        type: checkType == -1 ? "image" : "pdf",
                        src: src,
                    });
                } catch (error) {
                    console.log("🚀 ~ error:", error);
                }
            }
            this.isShow = true;
            this.selectedItemBanTheHienLenh = this.listGallery[0] || null;
            if (this.selectedItemBanTheHienLenh) {
                this.renderBanTheHien(this.selectedItemBanTheHienLenh.src);
            }
        },
        async LayBanTheHienLenhDienTu() {
            this.isShow = false;
            try {
                let lenh = new LenhDienTu();
                lenh.guidLenh = this.guidLenh;
                let link = await lenh.layLinkXemBanTheHienLenh();
                this.listGallery = [
                    {
                        index: 0,
                        type: "pdf",
                        src: link,
                    },
                ];
            } catch (error) {
                console.log("🚀 ~ error", error);
            } finally {
                this.isShow = true;
                this.selectedItemBanTheHienLenh = this.listGallery[0] || null;
                if (this.selectedItemBanTheHienLenh) {
                    this.renderBanTheHien(this.selectedItemBanTheHienLenh.src);
                }
            }
        },
        prevItem() {
            this.selectedItemBanTheHienLenh = this.listGallery[
                this.selectedItemBanTheHienLenh.index - 1
            ];
            this.renderBanTheHien(this.selectedItemBanTheHienLenh.src);
        },
        nextItem() {
            this.selectedItemBanTheHienLenh = this.listGallery[
                this.selectedItemBanTheHienLenh.index + 1
            ];
            this.renderBanTheHien(this.selectedItemBanTheHienLenh.src);
        },
        renderBanTheHien() {
            if (this.selectedItemBanTheHienLenh.type == "pdf") {
                this.pdfViewer(this.selectedItemBanTheHienLenh.src);
            } else {
                this.imageViewer();
            }
        },
        imageViewer() {
            let elem = document.getElementById("img-view");
            if (!elem) {
                setTimeout(() => {
                    this.imageViewer();
                }, 500);
                return;
            }
            this.panzoom = Panzoom(elem, {
                maxScale: 3, // Giới hạn tỉ lệ phóng tối đa
                minScale: 0.8, // Giới hạn tỉ lệ thu nhỏ tối thiểu
                zoomDoubleClickSpeed: 1,
                canvas: true,
                // panOnlyWhenZoomed: true,
            });
        },
        pdfViewer(url) {
            let self = this;
            import("../../../utils/pdfjs-2.9.359-dist/build/pdf").then((pdfjsLib) => {
                pdfjsLib.GlobalWorkerOptions.workerSrc = "/mylibs/pdf/pdf.worker.js";

                var loadingTask = pdfjsLib.getDocument(url);
                loadingTask.promise.then(function (pdf) {
                    pdf.getPage(1).then(function (page) {
                        var scale = 1.5;
                        var viewport = page.getViewport({ scale: scale });
                        // Support HiDPI-screens.
                        var outputScale = window.devicePixelRatio || 1;
                        //
                        // Prepare canvas using PDF page dimensions
                        //
                        let arrCanvas = document.querySelectorAll("#the-canvas");

                        arrCanvas.forEach((canvas) => {
                            var context = canvas.getContext("2d");
                            canvas.width = Math.floor(viewport.width * outputScale);
                            canvas.height = Math.floor(viewport.height * outputScale);
                            canvas.style.width = "100vw";
                            canvas.style.height = "auto";
                            var transform =
                                outputScale !== 1
                                    ? [outputScale, 0, 0, outputScale, 0, 0]
                                    : null;
                            //
                            // Render PDF page into canvas context
                            //
                            var renderContext = {
                                canvasContext: context,
                                transform: transform,
                                viewport: viewport,
                            };
                            page.render(renderContext);
                            self.panzoom = Panzoom(canvas, {
                                maxScale: 5, // Giới hạn tỉ lệ phóng tối đa
                                minScale: 1, // Giới hạn tỉ lệ thu nhỏ tối thiểu
                                zoomDoubleClickSpeed: 1,
                                canvas: true,
                                // panOnlyWhenZoomed: true,
                            });
                        });
                    });
                });
            });
        },
    },
    watch: {
        dialog: {
            immediate: true,
            handler: function () {
                if (this.dialog == true) {
                    this.getData();
                }
            },
        },
    },
    mounted() {},
};
</script>
<style scoped>
:deep(.dx-gallery.anh-ban-the-hien .dx-gallery-nav-button-next),
:deep(.dx-gallery.anh-ban-the-hien .dx-gallery-nav-button-prev) {
    position: absolute;
    top: 50%;
    width: 48px;
    height: 36px;
    background: 0 0;
    cursor: pointer;
    text-align: center;
}
.btn-prev {
    position: absolute;
    top: calc(50% - 18px);
    left: 4px;
    z-index: 1;
    border-radius: 50%;
    background: rgb(165 165 165 / 10%);
}
.btn-next {
    position: absolute;
    top: calc(50% - 18px);
    right: 4px;
    z-index: 1;
    border-radius: 50%;
    background: rgb(165 165 165 / 10%);
}
.btn-prev:hover,
.btn-next:hover {
    background: rgb(165 165 165 / 20%);
}
</style>
